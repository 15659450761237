import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import {
	CanActivate,
	ActivatedRouteSnapshot,
	Router,
} from '@angular/router';
import { Constants } from '../constants';

@Injectable()
export class RoleGuard implements CanActivate {
	constructor(
		private authService: MsalService,
		private router: Router) { }

	canActivate(
		route: ActivatedRouteSnapshot,
	): boolean {
		if (this.authService.instance.getAllAccounts().length > 0) {
			let allowedRoles = route.data.roles as Array<string>;
			const username = this.authService.instance.getAllAccounts()[0].username
			//add all as viewer
			let userRoles = this.authService.instance.getAllAccounts()[0].idTokenClaims.roles;
			if (!userRoles && this.isValamar(username)) {
				userRoles = [Constants.ROLES.VIEWER]
			}

			console.log("User roles roleguard: ", userRoles)
			//check user roles alowed
			if (userRoles) {
				for (var role of userRoles) {
					if (allowedRoles.includes(role)) {
						return true;
					}
				}
			}

			this.router.navigate(['authentication/login']);
			return false;
		}
		else {
			this.router.navigate(['authentication/login']);
			return false;
		}
	}

	isValamar(email: string): boolean {
		return email.endsWith("@valamar.com") || email.endsWith("@riviera.hr");
	}
}
