<!-- SIDEBAR -->
<!-- <mat-drawer-container class="main-sidebar" [class.sidebar-opened]="sidebar.opened" autosize> -->

<!-- <mat-drawer #sidebar class="main-sidebar" mode="side" opened="true"> -->
<!-- <app-sidebar></app-sidebar> -->
<!-- </mat-drawer> -->

<!-- NAVBAR -->
<div class="main-navbar">
  <div class="navbar-wrapper">
    <div class="navbar-content"></div>
    <div class="navbar-profile">
      <div class="justify-content-center align-items-center room-registry-dropdown ml-4">

        <button class="text-white hover:underline" mat-button
          (click)="navigateTo('/accommodationunit/home')"><span>Valamar Room
            Book</span>
        </button>
        <div *ngIf="isViewer == false">
          <button class="text-white" mat-button (click)="cbToogle($event)">
            <div *ngIf="!cbOpen" class="d-flex align-items-center">
              <span>Code Books</span>
              <mat-icon>keyboard_arrow_right</mat-icon>
            </div>

            <div *ngIf="cbOpen">
              <div class="d-flex align-items-center">
                <span>Code Books</span>
                <mat-icon>keyboard_arrow_down</mat-icon>
              </div>
              <div class="dropdown-menu position-absolute mt-2 p-2 border text-white">
                <div class="dropdown-container">
                  <!-- property -->
                  <button class="text-white" mat-button (click)="propertyToogle($event)">
                    <div *ngIf="!propertyOpen" class="d-flex align-items-center">
                      <span>Property</span>
                      <mat-icon>keyboard_arrow_right</mat-icon>
                    </div>
                    <div *ngIf="propertyOpen">
                      <div class="d-flex align-items-center">
                        <span>Property</span>
                        <mat-icon>keyboard_arrow_down</mat-icon>
                      </div>
                      <div class="dropdown-menu mt-2 p-2 border text-white">
                        <a (click)="navigateTo('/accommodationunit/autproperties')" class="dropdown-item">Properties</a>

                        <a (click)="navigateTo('/accommodationunit/propertytypes')" class="dropdown-item">Property
                          Types</a>
                        <a *ngIf="isAdmin" (click)="navigateTo('/accommodationunit/propertygrouptypes')"
                          class="dropdown-item">Property
                          Group Types</a>
                        <a (click)="navigateTo('/accommodationunit/propertygroups')" class="dropdown-item">Property
                          Groups</a>
                      </div>
                    </div>
                  </button>
                  <!-- property data -->
                  <button class="text-white" mat-button (click)="propertyDataToogle($event)">
                    <div *ngIf="!propertyDataOpen" class="d-flex align-items-center">
                      <span>Property Data</span>
                      <mat-icon>keyboard_arrow_right</mat-icon>
                    </div>
                    <div *ngIf="propertyDataOpen">
                      <div class="d-flex align-items-center">
                        <span>Property Data</span>
                        <mat-icon>keyboard_arrow_down</mat-icon>
                      </div>
                      <div class="dropdown-menu mt-2 p-2 border text-white">
                        <a *ngIf="isAdmin" (click)="navigateTo('/accommodationunit/roomcategory')"
                          class="dropdown-item">Room
                          Category</a>
                        <a (click)="navigateTo('/accommodationunit/roomtypename')" class="dropdown-item">Room Type
                          Name</a>
                        <a (click)="navigateTo('/accommodationunit/roomtypecode')" class="dropdown-item">Room Type
                          Code</a>
                        <a (click)="navigateTo('/accommodationunit/pmsroomclasscode')" class="dropdown-item">PMS Rooom
                          Clas Code
                          Code</a>
                        <a *ngIf="isAdmin" (click)="navigateTo('/accommodationunit/roomgrouptype')"
                          class="dropdown-item">Room Group
                          Type</a>
                        <a *ngIf="isAdmin" (click)="navigateTo('/accommodationunit/ground-plans')"
                          class="dropdown-item">Room Plans</a>
                        <a *ngIf="isAdmin" (click)="navigateTo('/accommodationunit/groundplansmain')"
                          class="dropdown-item">Grounf
                          Plans</a>
                        <a *ngIf="isAdmin" (click)="navigateTo('/accommodationunit/bedtypes')" class="dropdown-item">Bed
                          Types</a>
                        <a *ngIf="isAdmin" (click)="navigateTo('/accommodationunit/balcony-type')"
                          class="dropdown-item">Balcony
                          Types</a>
                        <a *ngIf="isAdmin" (click)="navigateTo('/accommodationunit/view')"
                          class="dropdown-item">Views</a>
                        <a *ngIf="isAdmin" (click)="navigateTo('/accommodationunit/bathroom')"
                          class="dropdown-item">Bathroom</a>
                        <a *ngIf="isAdmin" (click)="navigateTo('/accommodationunit/pitch-amenities')"
                          class="dropdown-item">Pitch
                          Amenities</a>
                        <a *ngIf="isAdmin" (click)="navigateTo('/accommodationunit/amenities')"
                          class="dropdown-item">Amenities</a>
                      </div>
                    </div>
                  </button>
                  <!-- Other -->
                  <div *ngIf="isAdmin">

                    <button class="text-white" mat-button (click)="propertyOtherToogle($event)">
                      <div *ngIf="!propertyOtherOpen" class="d-flex align-items-center">
                        <span>Others</span>
                        <mat-icon>keyboard_arrow_right</mat-icon>
                      </div>
                      <div *ngIf="propertyOtherOpen">
                        <div class="d-flex align-items-center">
                          <span>Others</span>
                          <mat-icon>keyboard_arrow_down</mat-icon>
                        </div>
                        <div class="dropdown-menu mt-2 p-2 border text-white">
                          <a (click)="navigateTo('/accommodationunit/seasontypes')" class="dropdown-item">Season
                            Types</a>
                          <a (click)="navigateTo('/accommodationunit/propertyserviceentities')"
                            class="dropdown-item">Property
                            Service Entities</a>
                          <a (click)="navigateTo('/accommodationunit/partner')" class="dropdown-item">Partner
                          </a>
                        </div>
                      </div>
                    </button>
                  </div>

                </div>
              </div>
            </div>

          </button>
        </div>

      </div>

      <button class="logout-btn" mat-button [matMenuTriggerFor]="menu">
        <span>{{getUsername()}}</span>
        <img id="avatar-image" class="ml-3 mb-2 rounded-circle" style="width: 35px;" />
        <!-- <mat-icon class="ml-3 mb-2">account_circle</mat-icon> -->
      </button>

      <mat-menu #menu="matMenu">
        <button class="logout-btn" mat-menu-item (click)="logout()">
          <mat-icon>exit_to_app</mat-icon>
          <span>Logout</span>
        </button>
      </mat-menu>
    </div>
  </div>
</div>

<!-- MAIN PANEL -->
<div class="main-panel">
  <div class="main-content p-1">
    <router-outlet></router-outlet>
  </div>
</div>
<!-- </mat-drawer-container> -->

<app-spinner></app-spinner>