import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';

import { Store } from '../../store';

@Injectable()
export class AuthenticationGuard implements CanActivate {
  constructor(
    private authService: MsalService,
    private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean {
    if (this.authService.instance.getAllAccounts().length > 0) {
      if (this.authService.instance.getAllAccounts()[0].idTokenClaims) {
        Store.role = this.authService.instance.getAllAccounts()[0].idTokenClaims?.roles;
      } else {
        console.log("no user roles")
        this.router.navigate(['authentication/login']);
        return false;
      }
      return true;
    }
    else {
      this.router.navigate(['authentication/login']);
      return false;
    }
  }
}