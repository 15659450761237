import { Role } from '../../../../../../shared/entities/enums/role.enum';
import { SidebarItem } from '../models/sidebar-item.model';
export const ROUTES: SidebarItem[] = [
  {
    label: 'Room Registry',
    routerLink: '',
    title: 'Accommodation',
    permission: ['Administrator', 'Editor', 'Viewer'],
    type: 'link',
    badge: 'ACC',
    items: [
      {
        label: 'ROOM REGISTER',
        routerLink: 'accommodationunit/home',
        title: 'ROOM REGISTER',
        permission: ['Administrator', 'Editor', 'Viewer'],
        type: 'link',
        badge: 'BT',
      },
      {
        label: 'PROPERTIES',
        routerLink: 'accommodationunit/autproperties',
        title: 'PROPERTIES',
        permission: ['Administrator', 'Editor'],
        type: 'link',
        badge: 'BT',
      },
    ],
  },
  {
    label: 'Code books',
    routerLink: '',
    title: 'Codes Database',
    permission: ['Administrator', 'Editor'],
    type: 'link',
    badge: 'CDB',
    items: [
      {
        label: 'PROPERTY',
        routerLink: '',
        title: 'PROPERTY',
        permission: ['Administrator', 'Editor'],
        type: 'link',
        badge: 'CDB',
        items: [
          // {
          //   routerLink: 'accommodationunit/autpropertyData',
          //   title: 'Room Property Data',
          //   permission: ['Administrator'],
          //   type: 'link',
          //   badge: 'BT',
          // },
          //{
          //   label: 'Room Property Data',
          //  routerLink: 'accommodationunit/autclasses',
          //  title: 'Room Categories',
          //  permission: ['Administrator'],
          //  type: 'link',
          //  badge: 'AC',
          //},
          {
            label: 'Property Types',
            routerLink: 'accommodationunit/propertytypes',
            title: 'Property Types',
            permission: ['Administrator'],
            type: 'link',
            badge: 'BT',
          },

          {
            label: 'Property Group Types',
            routerLink: 'accommodationunit/propertygrouptypes',
            title: 'Property Group Types',
            permission: ['Administrator'],
            type: 'link',
            badge: 'BT',
          },
          {
            label: 'Property Groups',
            routerLink: 'accommodationunit/propertygroups',
            title: 'Property Groups',
            permission: ['Administrator', 'Editor'],
            type: 'link',
            badge: 'BT',
          },

          {
            label: 'PMS Room Class Code',
            routerLink: 'accommodationunit/pmsroomclasscode',
            title: 'PMS Room Class Code',
            permission: ['Administrator', 'Editor'],
            type: 'link',
            badge: 'BT',
          },
        ],
      },

      {
        label: 'PROPERTY DATA',
        routerLink: '',
        title: 'PROPERTY DATA',
        permission: ['Administrator', 'Editor'],
        type: 'link',
        badge: 'CDB',
        items: [
          {
          label: 'Room Category',
          routerLink: 'accommodationunit/roomcategory',
          title: 'Room Category',
          permission: ['Administrator'],
          type: 'link',
          badge: 'AC',
          },
          {
            label: 'Room Type Name ',
            routerLink: 'accommodationunit/roomtypename',
            title: 'Room Types',
            permission: ['Administrator', 'Editor'],
            type: 'link',
            badge: 'AUT',
          },
          {
            label: 'Room Type Code ',
            routerLink: 'accommodationunit/roomtypecode',
            title: 'Room Types',
            permission: ['Administrator', 'Editor'],
            type: 'link',
            badge: 'AUT',
          },
          {
            label: 'Room Group Type',
            routerLink: 'accommodationunit/roomgrouptype',
            title: 'Room Group Type',
            permission: ['Administrator'],
            type: 'link',
            badge: 'AN',
          },
          {
            label: 'Room Plans',
            routerLink: 'accommodationunit/ground-plans',
            title: 'Room plans',
            permission: ['Administrator'],
            type: 'link',
            badge: 'BT',
          },
          {
            label: 'Ground Plans',
            routerLink: 'accommodationunit/groundplansmain',
            title: 'Ground plans',
            permission: ['Administrator'],
            type: 'link',
            badge: 'BT',
          },
          {
            label: 'Bed Types',
            routerLink: 'accommodationunit/bedtypes',
            title: 'Bed types&sizes',
            permission: ['Administrator'],
            type: 'link',
            badge: 'BT',
          },
          {
            label: 'Balcony Types',
            routerLink: 'accommodationunit/balcony-type',
            title: 'Balcony Types',
            permission: ['Administrator'],
            type: 'link',
            badge: 'BT',
          },
          {
            label: 'Views',
            routerLink: 'accommodationunit/view',
            title: 'Views',
            permission: ['Administrator'],
            type: 'link',
            badge: 'BT',
          },
          {
            label: 'Bathrooms',
            routerLink: 'accommodationunit/bathroom',
            title: 'Bathrooms',
            permission: ['Administrator'],
            type: 'link',
            badge: 'BT',
          },
          {
            label: 'Pitch Amenities',
            routerLink: 'accommodationunit/pitch-amenities',
            title: 'Pitch Amenities',
            permission: ['Administrator'],
            type: 'link',
            badge: 'BT',
          },
          {
            label: 'Amenities',
            routerLink: 'accommodationunit/amenities',
            title: 'Amenities',
            permission: ['Administrator'],
            type: 'link',
            badge: 'BT',
          },
          // {
          //   label: 'Room Photos',
          //   routerLink: 'accommodationunit/auphotos',
          //   title: 'Room Photos',
          //   permission: ['Administrator'],
          //   type: 'link',
          //   badge: 'BT',
          // },
        ],
      },

      {
        label: 'OTHERS',
        routerLink: '',
        title: 'OTHERS',
        permission: ['Administrator'],
        type: 'link',
        badge: 'CDB',
        items: [
          {
            label: 'Season Types',
            routerLink: 'accommodationunit/seasontypes',
            title: 'Season Types',
            permission: ['Administrator'],
            type: 'link',
            badge: 'BT',
          },
          {
            label: 'Property Services',
            routerLink: 'accommodationunit/propertyserviceentities',
            title: 'Property Services',
            permission: ['Administrator'],
            type: 'link',
            badge: 'BT',
          },
         // {
         //   label: 'Market Segments',
         //   routerLink: 'accommodationunit/marketsegments',
         //   title: 'Market Segments',
         //   permission: ['Administrator'],
         //   type: 'link',
         //   badge: 'BT',
         // },
          {
            label: 'Partner',
            routerLink: 'accommodationunit/partner',
            title: 'Partner',
            permission: ['Administrator'],
            type: 'link',
            badge: 'BT',
          },
         // {
         //   label: 'Blackout',
         //   routerLink: 'accommodationunit/blackout',
         //   title: 'Blackout',
         //   permission: ['Administrator'],
         //   type: 'link',
         //   badge: 'BT',
         // },
        ],
      },

      // {
      //   routerLink: 'accommodationunit/autadditionalattributetypes',
      //   title: 'Additional Attribute Types',
      //   permission: ['Administrator'],
      //   type: 'link',
      //   badge: 'BT',
      // },
      //{
      //  label: 'Room Property Data',
      //  routerLink: 'accommodationunit/autadditionalattributetypevalues',
      //  title: 'Additional Attribute Type Values',
      //  permission: ['Administrator'],
      //  type: 'link',
      //  badge: 'BT',
      //},
      // {
      //   routerLink: 'accommodationunit/dateperiods',
      //   title: 'Date Periods',
      //   permission: ['Administrator'],
      //   type: 'link',
      //   badge: 'BT',
      // },
      // {
      //  label: 'Room Property Data',
      //  routerLink: 'accommodationunit/keystructures',
      //  title: 'Key Structures',
      //  permission: ['Administrator'],
      //  type: 'link',
      //  badge: 'BT',
      //},
    ],
  },
];

// {
//   routerLink: '',
//   title: 'Administration',
//   permission: ['Administrator'],
//   type: 'link',
//   badge: 'AD',
//   items: [
//     {
//       routerLink: 'administration/roles',
//       title: 'Role',
//       permission: ['Administrator'],
//       type: 'link',
//       badge: '',
//     },
//     {
//       routerLink: 'administration/users',
//       title: 'User',
//       permission: ['Administrator'],
//       type: 'link',
//       badge: '',
//     },
//   ],
// },
