import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef } from '@angular/core';

/** COMPONENTS */
import { SidebarComponent } from '../../modules/core/sidebar/components/sidebar.component';
import { AuthenticationService } from '../../modules/core/authentication/authentication.service';
import { MsalService } from '@azure/msal-angular';
import { Router } from '@angular/router'; // Import the Router
import { Constants } from '../../shared/constants';
@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.css'],
})
export class MainLayoutComponent implements OnInit {

  @ViewChild(SidebarComponent, { static: true }) sidebar: SidebarComponent;
  vrbOpen: boolean = false;
  cbOpen: boolean = false;
  propertyOpen: boolean = false;
  propertyDataOpen: boolean = false;
  propertyOtherOpen: boolean = false;
  userRole: string;
  isViewer: boolean = false;
  isEditor: boolean = false;
  isAdmin: boolean = false;

  constructor(
    private authenticationService: AuthenticationService,
    private authService: MsalService,
    private httpClient: HttpClient,
    private router: Router, private changeDetectorRef: ChangeDetectorRef) {
  }
  ngOnInit(): void {
    this.setHighestUserRole()
    this.setUserRoleAccess()
  }

  setUserRoleAccess() {
    switch (this.userRole) {
      case Constants.ROLES.VIEWER:
        this.isViewer = true;
        break; // Prevent fallthrough to the next case
      case Constants.ROLES.EDITOR:
        this.isEditor = true;
        break; // Prevent fallthrough to the next case
      case Constants.ROLES.ADMINISTRATOR:
        this.isAdmin = true;
        break; // Prevent fallthrough to the next case
      default:
        this.isViewer = true; // Default case if no match is found
    }
  }


  setHighestUserRole() {

    //!! NB : Add all as viewer
    const userRoles = this.authService.instance.getAllAccounts()[0].idTokenClaims.roles || [Constants.ROLES.VIEWER];
    // Define role hierarchy: 'Administrator' > 'Editor' > 'Viewer'
    const roleHierarchy = [Constants.ROLES.ADMINISTRATOR, Constants.ROLES.EDITOR, Constants.ROLES.VIEWER];

    // Find the highest role from the user's roles based on hierarchy
    const highestRole = userRoles.reduce((highest, current) => {
      if (roleHierarchy.indexOf(current) < roleHierarchy.indexOf(highest)) {
        return current;
      }
      return highest;
    }, Constants.ROLES.VIEWER); // Default to 'Viewer' if no role is found 
    this.userRole = highestRole
  }

  logout() {
    this.authenticationService.logout();
  }

  getUsername() {
    return this.authService.instance.getAllAccounts()[0].username;
  }

  async getAvatar() {
    const accessTokenRequest = {
      scopes: ["user.read"],
      account: this.authService.instance.getAllAccounts()[0],
    };

    this.authService.acquireTokenSilent(accessTokenRequest).subscribe(
      {
        next: (result) => {
          this.httpClient.get(`https://graph.microsoft.com/v1.0/me/photos/48x48/$value`, {
            headers: {
              "Authorization": result.accessToken,
              "Accept": "image/jpeg",
            },
            responseType: "blob"
          }).subscribe(data => {
            const url = window.URL || window.webkitURL;
            let x = url.createObjectURL(data);
            document.getElementById("avatar-image").setAttribute("src", x);
            return x;
          },
            error => {
              console.log(error);
              return;
            }
          )

        },
        error: (error) => {
          console.log(error);
          return;
        }
      }
    )
  }

  ngAfterViewInit() {
    this.getAvatar();
  }

  vrbToogle(e: Event) {
    e.stopPropagation()
    this.vrbOpen = !this.vrbOpen
  }

  cbToogle(e: Event) {
    e.stopPropagation()
    this.propertyOtherOpen = false
    this.propertyDataOpen = false
    this.propertyOpen = false
    this.cbOpen = !this.cbOpen
  }
  propertyToogle(e: Event) {
    e.stopPropagation()
    this.propertyOtherOpen = false
    this.propertyDataOpen = false
    this.propertyOpen = !this.propertyOpen
  }

  propertyDataToogle(e: Event) {
    e.stopPropagation()
    this.propertyOtherOpen = false
    this.propertyOpen = false
    this.propertyDataOpen = !this.propertyDataOpen
  }

  propertyOtherToogle(e: Event) {
    e.stopPropagation()
    this.propertyDataOpen = false
    this.propertyOpen = false
    this.propertyOtherOpen = !this.propertyOtherOpen
  }

  navigateTo(route: string): void {
    this.router.navigate([route]).then(() => {
      // Refresh the page after navigation
      window.location.reload();
    });
  }

  // Close dropdowns if the click is outside of the component
  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent): void {
    this.closeAllMenus()
  }
  closeAllMenus() {
    if (this.vrbOpen) { this.vrbOpen = false }
    if (this.cbOpen) { this.cbOpen = false }
    if (this.propertyOpen) { this.propertyOpen = false }
    if (this.propertyDataOpen) { this.propertyDataOpen = false }
    if (this.propertyOtherOpen) { this.propertyOtherOpen = false }
    this.changeDetectorRef.markForCheck();
  }
}
