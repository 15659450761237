import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Role } from '../../../..//..//shared//entities//enums/role.enum';
import { ROUTES } from './data/routes';

/** DOMAIN */
import { SidebarItem } from './models/sidebar-item.model';

/** SERVICES */
import { SidebarService } from './../sidebar.service';

/** DATA */
//import { ROUTES } from './data/routes';
import { AccommodationUnitModule } from '../../../../accommodation-unit/accommodation-unit.module';
import { Store } from '../../../../../store';

@Component({
	selector: 'app-sidebar-items',
	templateUrl: 'sidebar-items.component.html',
	styleUrls: ['./sidebar-items.component.css'],
	//styleUrls: ['./sidebar-items.component.scss'],
})
export class SidebarItemsComponent implements OnInit {

	constructor(
	) { }

	sidebarItem: SidebarItem[];
	showFiller = false;

	ngOnInit() {
		this.sidebarItem = ROUTES;

		let userRoles = Store.role;
		console.log("sidebar items roloes : ", userRoles)
		this.sidebarItem = this.sidebarItem.filter(el => {
			var allowedRoles = el.permission;
			for (var role of userRoles) {
				if (allowedRoles.includes(role)) {
					return true;
				}
			}
			return false;
		});

		for (var item of this.sidebarItem) {
			item.items = item.items.filter(el => {
				var allowedRoles = el.permission;
				for (var role of userRoles) {
					if (allowedRoles.includes(role)) {
						return true;
					}
				}
				return false;
			});
		}

		for (var item of this.sidebarItem) {
			for (var item2 of item.items) {
				item2.items = item2?.items?.filter(el => {
					var allowedRoles = el.permission;
					for (var role of userRoles) {
						if (allowedRoles.includes(role)) {
							return true;
						}
					}
					return false;
				});
			}
		}
	}
}