/** ANGULAR MODULES */
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from '../environments/environment';

/** CUSTOM MODULES */
import { SidebarModule } from './modules/core/sidebar/sidebar.module';
import { LibModule } from './lib/lib.module';
import { NavbarModule } from './modules/core/navbar/navbar.module';
import {MatNativeDateModule} from '@angular/material/core';
/** COMPONENTS */
import { AppComponent } from './app.component';
import { AuthenticationLayoutComponent } from './layouts/authentication/authentication-layout.component';
import { MainLayoutComponent } from './layouts/main/main-layout.component';

import { SpinnerComponent } from './shared/components/spinner/spinner.component';

/** GUARDS */
import { AuthenticationGuard } from './shared/guards/authentication.guard';

/** ROUTES */
import { AppRoutingModule } from './app-routing.module';

/** SERVICES */
import { SpinnerInterceptor } from './shared/services/spinner-interceptor';
import { AuthInterceptor } from './shared/services/auth-interceptor';

import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatMenuModule} from '@angular/material/menu';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { ScrollPanelModule } from 'primeng/scrollpanel'
import { ScrollTopModule } from "primeng/scrolltop";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

/** AZURE AD */
import { MsalModule, MsalRedirectComponent, MsalGuard, MsalInterceptor } from "@azure/msal-angular";
import { PublicClientApplication, InteractionType } from "@azure/msal-browser";
import { RoleGuard } from './shared/guards/role.guard';

const isIE =
  window.navigator.userAgent.indexOf("MSIE ") > -1 ||
  window.navigator.userAgent.indexOf("Trident/") > -1;


@NgModule({
  declarations: [
    /** MAIN */
    AppComponent,
    AuthenticationLayoutComponent,
    MainLayoutComponent,

    /** SHARED */
    SpinnerComponent,
  ],
  imports: [
    AppRoutingModule,
    ScrollingModule,
    SidebarModule,
    LibModule,
    NavbarModule,
    MatNativeDateModule,
    HttpClientModule, FormsModule, 
    ReactiveFormsModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    CommonModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          clientId: environment.clientId,
          authority:environment.authority,
          redirectUri: environment.redirectUri,
          postLogoutRedirectUri: environment.redirectUri,
        },
        cache: {
          cacheLocation: "localStorage",
          storeAuthStateInCookie: isIE,  // Set to true for Internet Explorer 11
        },
      }),
      {
        interactionType: InteractionType.Redirect, // MSAL Guard Configuration
        authRequest: {
          scopes: ["user.read"],
        },
      },
      {
        interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
        protectedResourceMap: new Map([
          [environment.baseUrl, ["user.read"]],
        ]),
      }
    ),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    AuthenticationGuard,
	RoleGuard
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule { }
